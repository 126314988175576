<template>
  <div class="member-shop-giveaway-promo-conditions-block">
    <div class="flex justify-between items-center">
      <p class="card-title">優惠條件組合 {{ formIndex + 1 }}</p>
      <el-button class="underline" style="color: var(--danger)" type="text" @click="$emit('remove')">移除</el-button>
    </div>
    <el-form ref="conditionFormRef" :model="conditionFormData" :rules="conditionFormRules" label-position="left">
      <el-form-item label="指定商品滿" prop="quantity">
        <div class="flex items-center" style="gap: 20px">
          <el-input v-model="conditionFormData.quantity" class="small-input" />
          <p>件</p>
        </div>
      </el-form-item>
    </el-form>

    <el-button
      type="text"
      :class="Object.keys(formData).length === 5 ? '' : 'text-primary-100 underline'"
      :disabled="Object.keys(formData).length === 5"
      @click="addGift"
    >
      +新增指定贈品（至多五種贈品）
    </el-button>

    <el-form ref="formRef" :model="formData" :rules="formRules" label-position="left">
      <el-form-item
        v-for="(value, key, index) in formData"
        :key="key"
        :prop="`${key}.product`"
        :label="`可獲得指定贈品 ${index + 1}`"
      >
        <div class="flex items-center" style="gap: 20px">
          <el-select
            v-model="formData[key].product"
            clearable
            popper-class="select-popper"
            value-key="id"
            placeholder="請選擇商品"
            @visible-change="advanceSelectClick(key)"
            @clear="onProductRemove(key)"
          >
            <el-option
              :label="findProductName(formData[key])"
              :value="formData[key].product"
            />
          </el-select>
          <el-form-item :prop="`${key}.count`">
            <el-input v-model="formData[key].count" class="small-input" @input="$emit('update', compactFormData())" />
          </el-form-item>
          <p>件</p>
          <p v-if="showAndWord(index)">以及</p>
        </div>
      </el-form-item>
    </el-form>

    <MemberShopProductAdvanceSelect
      v-if="showProductSelectModal"
      :multiple="selectProduct ? false : true"
      :data="selectableProducts()"
      :max="maxSelectProduct()"
      :typeOptions="filteredProductType"
      @confirm="onAddProduct"
      @close="showProductSelectModal = false, selectProduct = null"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, ref, set, del, computed, watch } from 'vue'
import MemberShopProductAdvanceSelect from '@/components/Select/MemberShopProductAdvanceSelect.vue'
import { productTypeConfig } from '@/config/memberShop'
import { nanoid } from 'nanoid'
import { isDigitRules, noEmptyRules, rangeRules } from '@/validation'
import { find, map, get, omit } from 'lodash'
import eventBus from '@/utils/eventBus'
import formUtils from '@/utils/form'

export default defineComponent({
  name: 'MemberShopGiveawayPromoConditionsBlock',
  components: { MemberShopProductAdvanceSelect },
  props: {
    formsPass: { type: Boolean, default: false },
    formIndex: { type: [Number, String], default: '' },
    storeProducts: { type: Array, default: () => [] },
    giveawayData: { type: Object, default: () => ({}) },
  },
  emits: ['validate', 'remove', 'update:formPass'],
  setup (props, { emit }) {
    const syncFormPass = computed({
      get () {
        return props.formPass
      },
      set (data) {
        emit('update:formPass', data)
      },
    })

    eventBus.on('validate-conditions', async (e) => {
      const formPass = {
        condition: false,
        gift: false,
      }
      let pass = true
      if (conditionFormRef.value) formPass.condition = await formUtils.checkForm(conditionFormRef.value)
      if (formRef.value)formPass.gift = await formUtils.checkForm(formRef.value)
      if (!Object.keys({ ...formData }).length) pass = false // 如果沒有任何商品
      if (!formPass.condition || !formPass.gift) pass = false
      syncFormPass.value = pass
      // emit('validate', pass)
    })
    const selectProduct = ref(null)
    const conditionFormRef = ref(null)
    const conditionFormData = reactive({
      quantity: null,
    })
    const conditionFormRules = {
      quantity: [noEmptyRules(), isDigitRules()],
    }
    const formRef = ref(null)
    const formData = reactive({})
    /*
    formData:
      [id]: {
       product: { id: productId },
       count: 1
       }
    */
    const formRules = reactive({})
    const showProductSelectModal = ref(false)
    const maxSelectProduct = () => {
      const curLength = Object.keys(formData).length
      return 5 - curLength
    }
    const filteredProductType = computed(() => {
      return omit(productTypeConfig, ['cashback', 'coupon'])
    })
    const compactFormData = () => {
      const gifts = map(formData, i => ({
        memberStoreProductId: i.product.id,
        quantity: Number(i.count),
      }))
      return {
        threshold: Number(conditionFormData.quantity),
        gifts,
      }
    }

    const showAndWord = (index) => {
      const total = Object.keys(formData).length
      if (total > 1 && index !== total - 1) return true
      return false
    }

    const addGift = () => {
      if (Object.keys({ ...formData }).length === 5) return
      showProductSelectModal.value = true
    }

    const selected = () => {
      const values = []
      for (const key in formData) {
        values.push(formData[key].product.id)
      }
      return values
    }

    const selectableProducts = () => {
      const products = selected()
      // TOFIX mocks
      return props.storeProducts.filter(p => !products.includes(p.id) && !p.isPaymentPromise)
    }

    const findProductName = (item) => {
      const id = item.product.id
      const product = find(props.storeProducts, { id })
      if (product) return product.name
      return ''
    }

    const onAddProduct = (products) => {
      if (selectProduct.value) {
        set(formData, selectProduct.value, { product: { id: products[0] }, count: 1 })
        selectProduct.value = null
        emit('update', compactFormData())
        return
      }
      for (const productId of products) {
        const id = nanoid(10)
        set(formData, `product_${id}`, { product: { id: productId }, count: 1 })
        set(formRules, `product_${id}.product`, [noEmptyRules()])
        set(formRules, `product_${id}.count`, [noEmptyRules(), rangeRules(1, 9999), isDigitRules()])
      }
      emit('update', compactFormData())
    }

    const onProductRemove = (key) => {
      del(formData, key)
    }

    const advanceSelectClick = (key) => {
      showProductSelectModal.value = true
      selectProduct.value = key
    }

    // TOFIX 更換 mocks 為正式商品資料
    // const mocks = [
    //   { name: 'dasda', type: 'dasads', price: 123124, id: 1 },
    //   { name: 'dascasda', type: 'dasads', price: 123124, id: 2 },
    //   { name: 'dasdcas1a', type: 'dasads', price: 123124, id: 3 },
    // ]

    watch(conditionFormData, () => {
      emit('update', compactFormData())
    })

    return {
      syncFormPass,
      addGift,
      conditionFormRef,
      conditionFormData,
      conditionFormRules,
      formRef,
      formData,
      formRules,
      showProductSelectModal,
      selectableProducts,
      onAddProduct,
      findProductName,
      onProductRemove,
      showAndWord,
      productTypeConfig,
      filteredProductType,
      selectProduct,
      advanceSelectClick,
      compactFormData,
      maxSelectProduct,
    }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .small-input.el-input {
  @apply w-[100px];
}
</style>
