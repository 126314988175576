<template>
  <div class="member-shop-giveaway-info-edit-block">
    <p class="card-title">指定商品</p>
    <div class="flex justify-between items-center" style="max-width: 560px">
      <el-button class="underline" type="text" @click="addProduct">選擇指定商品</el-button>
      <el-button class="underline" style="color: var(--danger)" type="text" @click="removeAll">移除全部</el-button>
    </div>
    <el-form ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <el-form-item
        v-for="(value, key) in formData"
        :key="key"
        :prop="key"
      >
        <el-select
          v-model="formData[key]"
          popper-class="select-popper"
          placeholder="請選擇商品"
          @visible-change="advanceSelectClick(key)"
        >
          <el-option
            :label="findProductName(formData[key])"
            :value="formData[key]"
          />
        </el-select>
        <el-button class="underline" style="color: var(--danger); margin-left: 16px" type="text" @click="onProductRemove(key)">移除</el-button>
      </el-form-item>
    </el-form>

    <MemberShopProductAdvanceSelect
      v-if="showProductSelectModal"
      multiple
      :data="selectableProducts()"
      :typeOptions="filteredProductType"
      @confirm="onAddProduct"
      @close="showProductSelectModal = false"
    />
  </div>
</template>

<script>
import { noEmptyRules } from '@/validation'
import { defineComponent, ref, reactive, computed, set, del, watch } from 'vue'
import MemberShopProductAdvanceSelect from '@/components/Select/MemberShopProductAdvanceSelect.vue'
import { productTypeConfig } from '@/config/memberShop'
import eventBus from '@/utils/eventBus'
import formUtils from '@/utils/form'
import { nanoid } from 'nanoid'
import { find, omit, values as objValues } from 'lodash'

export default defineComponent({
  name: 'MemberShopGiveawayProductsEditBlock',
  components: { MemberShopProductAdvanceSelect },
  props: ['formsPass', 'storeProducts'],
  emits: ['validate', 'update:formPass'],
  setup (props, { emit }) {
    const syncFormPass = computed({
      get () {
        return props.formPass
      },
      set (data) {
        emit('update:formPass', data)
      },
    })

    eventBus.on('validate-products', async (e) => {
      let pass = false
      if (formRef.value) pass = await formUtils.checkForm(formRef.value)
      if (!Object.keys({ ...formData }).length) {
        pass = false // 如果沒有任何商品
      }
      if (!pass) window.$message.warning('請設定指定商品')
      syncFormPass.value = pass
      // emit('validate', pass)
    })

    const showProductSelectModal = ref(false)
    const selectProduct = ref(null)
    const formRef = ref()
    const formData = reactive({})
    const formRules = reactive({})

    const selected = () => {
      const values = []
      for (const key in formData) {
        values.push(formData[key])
      }
      return values
    }

    const compactData = computed(() => {
      return objValues(formData)
    })
    const filteredProductType = computed(() => {
      return omit(productTypeConfig, ['cashback', 'coupon'])
    })
    const selectableProducts = () => {
      const products = selected()
      // TOFIX mocks
      return props.storeProducts.filter(p => !products.includes(p.id))
    }
    // TOFIX 更換 mocks 為正式商品資料
    // const mocks = [
    //   { name: 'dasda', type: 'dasads', price: 123124, id: 1 },
    //   { name: 'dascasda', type: 'dasads', price: 123124, id: 2 },
    //   { name: 'dasdcas1a', type: 'dasads', price: 123124, id: 3 },
    // ]

    const addProduct = () => {
      showProductSelectModal.value = true
    }

    const onProductRemove = (key) => {
      del(formData, key)
      del(formRules, key)
    }

    const findProductName = (id) => {
      const product = find(props.storeProducts, { id })
      if (product) return product.name
      return ''
    }

    const onAddProduct = (products) => {
      if (selectProduct.value) {
        set(formData, selectProduct.value, products[0])
        selectProduct.value = null
        return
      }
      for (const product of products) {
        const id = nanoid(10)
        set(formData, `product_${id}`, product)
        set(formRules, `product_${id}`, [noEmptyRules()])
      }
    }

    const removeAll = () => {
      for (const key in formData) {
        del(formData, key)
        del(formRules, key)
      }
    }

    const advanceSelectClick = (key) => {
      showProductSelectModal.value = true
      selectProduct.value = key
    }

    watch(formData, () => {
      emit('update', compactData.value)
    })

    return {
      formData,
      formRef,
      formRules,
      addProduct,
      removeAll,
      showProductSelectModal,
      productTypeConfig,
      filteredProductType,
      onAddProduct,
      selectableProducts,
      selected,
      onProductRemove,
      findProductName,
      compactData,
      advanceSelectClick,
      selectProduct,
      syncFormPass,
    }
  },
})
</script>

<style lang="postcss">
.select-popper {
  @apply hidden;
}
</style>
